/* eslint-disable @typescript-eslint/no-empty-function */
import { NgxPermissionsService } from 'ngx-permissions';

import { Component, effect, inject, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CONFIRM_SERVICE_TOKEN, ROUTER_FACADE_TOKEN } from '@do/app-common';
import { BaseDto } from '@do/common-dto';

import { BaseComponent } from './base.component';
import { EntityDataSignalStore } from './entity-domain-store';
import { EntityFeatureSignalStore } from './entity-feature-store';

@Component({
  template: '',
})
export abstract class BaseEntityDetailContainer<
  T extends BaseDto,
  F
> extends BaseComponent {
  protected fb = inject(FormBuilder);
  protected permissionsService = inject(NgxPermissionsService);
  protected routerFacade = inject(ROUTER_FACADE_TOKEN);
  protected confirmService = inject(CONFIRM_SERVICE_TOKEN);
  abstract domainStore: EntityDataSignalStore<T>;
  abstract featureStore: EntityFeatureSignalStore<T, F>;
  abstract formInitialize(entity: T | null): void;
  abstract form: FormGroup<{ [K in keyof F]: FormControl<F[K]> }>;
  abstract saveEntity(): Promise<T | undefined>;

  constructor() {
    super();
    // effect(
    //   () => {
    //     const entity = this.featureStore.entity();
    //     this.formInitialize(entity);
    //   },
    //   { allowSignalWrites: true }
    // );

    effect(
      () => {
        const isNew = this.featureStore.isNew();

        if (isNew) {
          this.editMode.set(true);
        }
      },
      { allowSignalWrites: true }
    );
  }

  forceCanDeactivate = false;
  editMode = signal(false);
  formIsSubmitted = signal(false);
  canDeactivate = signal(true);

  async delete() {
    await this.featureStore.formDelete();
  }

  edit() {
    this.editMode.set(true);
    this.formInitialize(this.featureStore.loadedEntity());
  }

  async save() {
    this.formIsSubmitted.set(true);

    if (this.form.valid) {
      const result = await this.saveEntity();

      if (result) {
        this.cancel();
      }
    } else {
      // todo show toast?
    }
  }

  cancel() {
    this.editMode.set(false);
    this.form.reset();
  }
}
