import { takeUntil } from 'rxjs';

import { Location } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { BreadcrumbItem, ROUTER_FACADE_TOKEN } from '@do/app-common';
import { BaseDto } from '@do/common-dto';

import { BaseComponent } from './base.component';
import { EntityFeatureSignalStore } from './entity-feature-store';
import { WithChildSignalStore } from './with-child-navigation';

@Component({
  template: '',
})
export abstract class BaseChildContainer<
  T extends BaseDto,
  K,
  C extends BaseDto
> extends BaseComponent {
  abstract featureStore: EntityFeatureSignalStore<T, K> &
    WithChildSignalStore<C>;
  abstract childEntity: string;
  abstract fixedChildProperty: string;

  abstract reloadIframe(): void;

  routerFacade = inject(ROUTER_FACADE_TOKEN);
  location = inject(Location);

  path = signal('');

  childId = computed(() => {
    const path = this.path()
      .split('?')[0]
      .split('/')
      .filter((p) => p && p != 'iframe' && p != 'modal');
    return path.slice(2).join('/');
  });

  queryParams = computed(
    () => `${this.fixedChildProperty}=${this.routerFacade.params()['id']}`
  );

  onChildSaved($event: C) {
    this.featureStore.savedChild($event);
  }

  onChildDeleted($event: { id: string }) {
    this.featureStore.deletedChild($event.id);
  }

  constructor() {
    super();
    this.location.onUrlChange((url) => {
      this.path.set(url);
    });

    this.routerFacade.reloadIframe
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        // console.log(
        //   'Reloading iframe for child ' +
        //     this.childEntity +
        //     'with id: ' +
        //     this.childId()
        // );
        this.reloadIframe();
      });
  }

  ngOnInit(): void {
    this.path.set(this.location.path());
  }

  onBreadcrumbChanged($event: BreadcrumbItem[]) {
    this.featureStore.setChildBreadcrumb($event);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.featureStore.clearChildBreadcrumb();
  }
}
