/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash';

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FilterModel,
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Component, effect, inject, OnInit } from '@angular/core';
import { mapFromColumnState, mapFromFilterModel } from '@do/app-common';
import { LoaderStore } from '@do/app-loader';
import { BaseDto } from '@do/common-dto';
import { FilterValue } from '@do/common-interfaces';

import { BaseEntityListContainer } from './base-entity-list.container';

@Component({
  template: '',
})
export abstract class BaseEntityPagedListContainer<T extends BaseDto, K>
  extends BaseEntityListContainer<T, K>
  implements OnInit
{
  searchFilters: FilterValue[] = [];

  loaderStore = inject(LoaderStore);

  constructor() {
    super();
    effect(() => {
      const pageSize = this.featureStore.gridState.pageSize();
      setTimeout(() => {
        this.setDatasource(pageSize);
      }, 0);
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const pageSize = this.featureStore.gridState.pageSize();

    const customGridOptions = {
      pagination: true,
      rowModelType: 'serverSide',
      serverSideInfiniteScroll: true, // al posto di serverSideStoreType dalla 28, true partial, false full
      // serverSideStoreType: 'partial',
      cacheBlockSize: pageSize,
      paginationPageSize: pageSize,
      onGridReady: (e: GridReadyEvent) => this.pagedGridReady(e),
    } as GridOptions;
    this.gridOptions = Object.assign(this.commonGridOptions, customGridOptions);
  }

  pagedGridReady(event: GridReadyEvent) {
    super.onGridReady(event);

    event.api.updateGridOptions(this.gridOptions);
  }

  protected setDatasource(pageSize: number) {
    this.gridApi?.setGridOption('serverSideDatasource', {
      destroy: () => {
        // console.log('destroy datasource');
      },
      getRows: async (params) => {
        // Anti redux pattern
        const id = this.loaderStore.showLoader();

        this.featureStore.setPageLoaded(false);

        const presetFilters = this.presetFilters();
        const fields = this.fields();

        // TODO ANDREA: sistemare filtri di tipo datetime
        const filters = mapFromFilterModel(
          params.request.filterModel as FilterModel,
          fields
        );

        this.searchFilters.forEach((sf) => {
          const index = filters.findIndex((f) => f.field === sf.field);
          if (index >= 0) {
            filters[index] = sf;
          } else {
            filters.push(sf);
          }
        });

        presetFilters.forEach((sf) => {
          const index = filters.findIndex(
            (f) =>
              f.field === sf.field &&
              f.type === sf.type &&
              f.operator === sf.operator &&
              isEqual(f.value, sf.value)
          );
          if (index >= 0) {
            filters[index] = sf;
          } else {
            filters.push(sf);
          }
        });

        const orderBy = mapFromColumnState(params.request.sortModel);

        const result = await this.getPaged(pageSize, params, orderBy, filters);

        if (result) {
          params.success({
            rowData: result.items,
            rowCount: result.totalCount,
          });

          this.featureStore.setTotalRows(result.totalCount);
          this.featureStore.setPageLoaded(true);
        } else {
          params.fail();
        }

        this.loaderStore.hideLoader(id);
      },
    });
  }

  protected async getPaged(
    pageSize: number,
    params: IServerSideGetRowsParams<T>,
    orderBy: { [field: string]: 'asc' | 'desc' },
    filters: FilterValue[]
  ) {
    const result = await this.domainStore.loadPaged(
      pageSize,
      params.request.startRow || 0,
      orderBy,
      filters,
      this.join
    );
    return result;
  }
}
