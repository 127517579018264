import { Location } from '@angular/common';
import { effect, inject } from '@angular/core';
import { BreadcrumbItem, ROUTER_FACADE_TOKEN } from '@do/app-common';
import { BaseDto } from '@do/common-dto';

import { BaseRootContainer } from './base-root.container';
import { EntityFeatureSignalStore } from './entity-feature-store';
import { WithChildSignalStore } from './with-child-navigation';

export abstract class BaseRootWithChildContainer<
  T extends BaseDto,
  F,
  C extends BaseDto
> extends BaseRootContainer<T, F> {
  abstract override featureStore: EntityFeatureSignalStore<T, F> &
    WithChildSignalStore<C>;

  override async navigate(idx: number, items: BreadcrumbItem[]) {
    await super.navigate(idx, items);

    const currentBreadcrumbLength = this.featureStore.breadcrumb().length;
    if (idx >= currentBreadcrumbLength) {
      this.featureStore.setChildBreadcrumb(
        items.slice(currentBreadcrumbLength, idx + 1)
      );
      this.router.reloadIframe.next();
    }
  }

  constructor() {
    super();
    const locationService = inject(Location);
    const routerFacade = inject(ROUTER_FACADE_TOKEN);
    effect(
      async () => {
        const childBreadcrumb = this.featureStore.childBreadcrumb();

        if (childBreadcrumb.length) {
          const childUrl = childBreadcrumb.map((f) => f.link);

          const currentUrl = locationService
            .path()
            .split('?')[0]
            .split('/')
            .filter((p) => p && p != 'iframe');

          const currentUrlWithoutChild = currentUrl.slice(0, 2);

          const newUrl = [...currentUrlWithoutChild, ...childUrl].join('/');
          const oldUrl = [...currentUrl].join('/');
          if (newUrl !== oldUrl) {
            locationService.replaceState(newUrl);
          }
        }
        await routerFacade.notifyBreadcrumbChangedToOpener(
          this.featureStore.breadcrumbWithChild()
        );
      },
      { allowSignalWrites: true }
    );
  }
}
