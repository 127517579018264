import { filter, first, map } from 'rxjs';

import { inject, Type } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { BaseDto } from '@do/common-dto';

import { EntityFeatureSignalStore } from './entity-feature-store';

export function baseLoadDetailGuard<
  E extends BaseDto,
  F,
  FS extends EntityFeatureSignalStore<E, F>
>(featureStoreType: Type<FS>): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const store = inject(featureStoreType);
    // const routerFacade = inject(ROUTER_FACADE_TOKEN);
    const id = route.params['id'] as string;

    return toObservable(store.loadedEntity).pipe(
      map((item) => {
        const isNew = id === '0';
        const load = !isNew && (item === undefined || item?.id !== id);
        if (load) {
          store.loadDetail(id);
        } else {
          item = !isNew ? item : null;
        }

        // if (item) {
        //   const name = store.getDetailBreadcrumbDescription(item);
        //   routerFacade.notifyNavigatedToOpener(name, id);
        // }

        return !load;
      }),
      filter((loaded) => loaded),
      first()
    );
  };
}
