import { ColumnState } from '@ag-grid-community/core';
import { computed, inject, Type } from '@angular/core';
import {
  AgGridFilterModel,
  BreadcrumbItem,
  CONFIRM_SERVICE_TOKEN,
  FilterFormValueInterface,
  GridState,
  ROUTER_FACADE_TOKEN,
  TOAST_SERVICE_TOKEN,
} from '@do/app-common';
import { LoaderStore } from '@do/app-loader';
import { BaseDto } from '@do/common-dto';
import { FilterValue } from '@do/common-interfaces';
import { DeepPartial } from '@do/common-utils';
import { marker } from '@do/ngx-translate-extract-marker';
import {
  patchState,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import {
  EntityDataSignalStore,
  SignalProperties,
  SignalState,
} from './entity-domain-store';

export interface EntityFeatureState<E extends BaseDto, F> {
  entityForm: F;
  entityFilterForm: FilterFormValueInterface;
  gridState: GridState;
  loadedEntity: E | null;
  showAdvancedFilters: boolean;
  // editMode: boolean;
}

export interface EntityFeatureComputedProperties<E extends BaseDto> {
  canDelete: boolean;
  canSave: boolean;
  canAdd: boolean;
  canEdit: boolean;
  selectedId: string | null;
  isNew: boolean;
  entity: E | null;
  breadcrumb: BreadcrumbItem[];
}

export type EntityFeatureMethods<E extends BaseDto> = {
  setPageSize: (pageSize: number) => void;
  setCurrentPage: (currentPage: number) => void;
  loadDetail: (id: string) => void;
  // formInitialize: (
  //   item: T | undefined,
  //   disable?: boolean,
  //   options?: any,
  //   permissions?: string[]
  // ) => void;
  setPageLoaded: (loaded: boolean) => void;
  setTotalRows: (totalRows: number) => void;
  formSave: (dto: DeepPartial<E>) => Promise<E | undefined>;
  formDelete: () => Promise<boolean>;
  // resetForm: () => void;
  setGridState: (gridState: GridState) => void;
  setFilterModel: (filterModel: AgGridFilterModel) => void;
  setColumnState: (sortModel: ColumnState[]) => void;
  setFilterFormValue: (value: FilterValue[]) => void;

  displayFormError: () => void;
  selectEntityId: (id: string) => void;
  getDetailBreadcrumbDescription: (item: E) => string;
  // downloadExcel: (
  //   fileName?: string,
  //   columns?: FieldConfig[],
  //   join?: string[],
  //   filters?: FilterValue[],
  //   orderBy?: { [field: string]: 'asc' | 'desc' }
  // ) => void;

  addEntity: () => void;
  selectItem: (item: E) => void;

  toggleAdvancedFilters: (show: boolean) => void;

  // setEditMode: (editMode: boolean) => void;
};

export type EntityFeatureSignalStore<
  E extends BaseDto,
  F
> = EntityFeatureMethods<E> &
  SignalState<EntityFeatureState<E, F>> &
  SignalProperties<EntityFeatureComputedProperties<E>>;

export function withEntityFeatureStore<E extends BaseDto, F>(
  domainStoreType: Type<EntityDataSignalStore<E>>,
  initialState: Partial<EntityFeatureState<E, F>>,
  initialFormValue: F,
  newEntityText: string,
  entityListText: string,
  getDetailBreadcrumbDescription: (item: E) => string
) {
  const defaultInitialState: EntityFeatureState<E, F> = {
    // set initial required properties
    entityForm: initialFormValue,
    entityFilterForm: {
      filters: [],
    },
    gridState: {
      currentPage: 0,
      pageSize: 20,
      totalRows: 0,
      pageLoaded: false,
      filterModel: {},
      columnState: undefined,
    },
    loadedEntity: null,
    showAdvancedFilters: false,
    // editMode: false,
  };

  return signalStoreFeature(
    withState<EntityFeatureState<E, F>>(
      Object.assign(defaultInitialState, initialState || {})
    ),

    withComputed(() => {
      const domainStore = inject(domainStoreType);
      const translateService = inject(TranslateService);
      const routerFacade = inject(ROUTER_FACADE_TOKEN);
      const selectedId = computed(() => routerFacade.params()['id']);
      const isNew = computed(() => selectedId() === '0');

      const entity = computed(() => {
        const id = selectedId();
        if (id != null) {
          return domainStore.entityMap()[id];
        }
        return null;
      });

      const computedState: SignalProperties<
        EntityFeatureComputedProperties<E>
      > = {
        isNew,
        selectedId,
        entity,
        canSave: computed(() => {
          return isNew() ? domainStore.canCreate() : domainStore.canUpdate();
        }),
        canDelete: computed(() => {
          return !isNew() && domainStore.canDelete();
        }),
        canAdd: computed(() => domainStore.canCreate()),
        canEdit: computed(() => domainStore.canUpdate()),
        breadcrumb: computed(() => {
          const main: BreadcrumbItem[] = [
            {
              link: '.',
              text: translateService.instant(entityListText),
            },
          ];

          const id = selectedId();

          if (id != null) {
            const currentEntity = entity();
            return [
              ...main,
              {
                link: id.toString(),
                text: currentEntity
                  ? getDetailBreadcrumbDescription(currentEntity) ||
                    currentEntity.id
                  : translateService.instant(newEntityText),
              },
            ];
          }
          return [];
        }),
      };

      return computedState;
    }),
    withMethods((store) => {
      const domainStore = inject(domainStoreType);
      const loaderStore = inject(LoaderStore);
      const translateService = inject(TranslateService);
      const toastService = inject(TOAST_SERVICE_TOKEN);
      const routerFacade = inject(ROUTER_FACADE_TOKEN);

      const confirmService = inject(CONFIRM_SERVICE_TOKEN);

      const methods: EntityFeatureMethods<E> = {
        // setEditMode: (editMode: boolean) => patchState(store, { editMode }),
        setFilterFormValue(value: FilterValue[]) {
          patchState(store, {
            entityFilterForm: {
              filters: value,
            },
          });
        },
        async loadDetail(id: string) {
          const actionId = loaderStore.showLoader();

          const result = await domainStore.loadSingle(id);
          if (result) {
            patchState(store, { loadedEntity: result });
          }

          loaderStore.hideLoader(actionId);
        },

        async formSave(dto: DeepPartial<E>) {
          const id = store.entity()?.id;
          // const queryParams = routerFacade.queryParams();

          // const param = queryParams['param']
          //   ? JSON.parse(atob(queryParams['param']))
          //   : undefined;

          const actionId = loaderStore.showLoader();

          let result: E | undefined;
          if (id && id != '0') {
            result = await domainStore.update(id, dto);

            if (result) {
              patchState(store, { loadedEntity: result });
            }
          } else {
            result = await domainStore.add(dto);

            if (result) {
              routerFacade.navigateRelative(['../', result?.id], true);
            }
          }

          if (result) {
            toastService.successFeedback(
              translateService.instant(marker('Save completed'))
            );
            await routerFacade.notifySavedItemToOpener(result);
          } else {
            toastService.errorFeedback(translateService.instant('Save failed'));
          }

          loaderStore.hideLoader(actionId);

          return result;
        },

        async formDelete() {
          const id = store.entity()?.id;
          if (id) {
            const confirm = await confirmService.deleteConfirm();

            if (confirm) {
              const result = await domainStore.delete(id);

              if (result) {
                toastService.successFeedback(
                  translateService.instant(marker('Delete completed'))
                );

                await routerFacade.notifyDeletedItemToOpener(id);
                routerFacade.back();
                // setTimeout(() => , 0);
              } else {
                toastService.errorFeedback(
                  translateService.instant(marker('Delete failed'))
                );
              }

              return result;
            }

            return false;
          }
          return false;
        },
        displayFormError() {
          toastService.errorFeedback(
            translateService.instant(
              marker('Form fields are not filled correctly')
            )
          );
        },
        getDetailBreadcrumbDescription,
        selectEntityId(id: string) {
          routerFacade.navigateRelative([id]);
        },
        addEntity() {
          routerFacade.navigateRelative([0]);
          patchState(store, { loadedEntity: null });
        },
        async selectItem(item: E) {
          await routerFacade.notifySelectedItemToOpener(item);
        },
        setGridState(gridState: GridState) {
          patchState(store, {
            gridState: gridState,
          });
        },
        setCurrentPage(currentPage: number) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, currentPage },
          }));
        },
        setPageSize(pageSize: number) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, pageSize },
          }));
        },
        setTotalRows(totalRows: number) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, totalRows },
          }));
        },
        setPageLoaded(pageLoaded: boolean) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, pageLoaded },
          }));
        },
        setColumnState(columnState: ColumnState[] | undefined) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, columnState },
          }));
        },
        setFilterModel(filterModel: AgGridFilterModel | undefined) {
          patchState(store, (state) => ({
            gridState: { ...state.gridState, filterModel },
          }));
        },
        toggleAdvancedFilters(show: boolean) {
          patchState(store, {
            showAdvancedFilters: show,
          });
        },
      };
      return methods;
    })
  );
}
