import { concat } from 'lodash';

import { computed, inject, Signal, Type } from '@angular/core';
import {
  BreadcrumbItem,
  ROUTER_FACADE_TOKEN,
  TOAST_SERVICE_TOKEN,
} from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';

import {
  EntityDataSignalStore,
  SignalProperties,
  SignalState,
} from './entity-domain-store';

export interface WithChildState {
  childBreadcrumb: BreadcrumbItem[];
}

const initialState: WithChildState = {
  childBreadcrumb: [],
};

export type WithChildMethods<K extends BaseDto> = {
  setChildBreadcrumb: (breadcrumb: BreadcrumbItem[]) => void;
  clearChildBreadcrumb: () => void;
  savedChild: (item: K) => void;
  deletedChild: (id: string) => void;
  deleteChild: (id: string) => Promise<void>;
};

export interface WithChildComputedProperties {
  breadcrumbWithChild: BreadcrumbItem[];
}

export type WithChildSignalStore<K extends BaseDto> = WithChildMethods<K> &
  SignalState<WithChildState> &
  SignalProperties<WithChildComputedProperties>;

// type RemoveNonArrayKeys<T, TValue> = keyof {
//   [K in keyof T as T[K] extends TValue[] | undefined ? K : never]: T[K];
// };

// function get<T, K>(container: T, key: RemoveNonArrayKeys<T, K>): K[] {
//   return container[key];
// }

export function withChildNavigation<T extends BaseDto, K extends BaseDto>(
  domainStoreType: Type<EntityDataSignalStore<K>>,
  // key: RemoveNonArrayKeys<T, K>
  key: keyof {
    [X in keyof T as T[X] extends K[] | undefined ? X : never]: T[X];
  }
) {
  return signalStoreFeature(
    {
      state: type<{
        loadedEntity: T | null;
      }>(),
      signals: type<{
        breadcrumb: Signal<BreadcrumbItem[]>;
      }>(),
    },
    withState(initialState),
    withComputed((store) => {
      return {
        breadcrumbWithChild: computed(() => {
          return [...store.breadcrumb(), ...store.childBreadcrumb()];
        }),
      };
    }),
    withMethods((store) => {
      const chainDomainStore = inject(domainStoreType);
      const toast = inject(TOAST_SERVICE_TOKEN);
      const routerFacade = inject(ROUTER_FACADE_TOKEN);

      return {
        setChildBreadcrumb(childBreadcrumb: BreadcrumbItem[]) {
          patchState(store, {
            childBreadcrumb: childBreadcrumb.filter((cb) => cb.link !== '.'),
          });
        },
        clearChildBreadcrumb() {
          patchState(store, {
            childBreadcrumb: [],
          });
        },
        savedChild: (item: K) => {
          const loadedEntity = store.loadedEntity();
          if (loadedEntity) {
            const isUpdated =
              (loadedEntity[key] as K[]).find((i) => i.id === item.id) != null;

            if (isUpdated) {
              patchState(store, {
                loadedEntity: {
                  ...loadedEntity,
                  [key]: (<K[]>loadedEntity[key])?.map((cc) =>
                    cc.id === item.id ? { ...item } : cc
                  ),
                },
              });

              chainDomainStore.updated(item.id, item);
            } else {
              patchState(store, {
                loadedEntity: {
                  ...loadedEntity,
                  [key]: concat(loadedEntity[key] as K[], [item]),
                },
              });
              chainDomainStore.added(item);
              routerFacade.navigateRelative(['../', item.id], true);
            }
          }
        },
        deleteChild: async (id: string) => {
          const loadedEntity = store.loadedEntity();
          if (loadedEntity) {
            const deleted = await chainDomainStore.delete(id);
            if (deleted) {
              patchState(store, {
                loadedEntity: {
                  ...loadedEntity,
                  [key]: (<K[]>loadedEntity[key])?.filter((c) => c.id !== id),
                },
              });
              toast.successFeedback('Delete completed');
            }
          }
        },
        deletedChild: (id: string) => {
          const loadedEntity = store.loadedEntity();
          if (loadedEntity) {
            patchState(store, {
              loadedEntity: {
                ...loadedEntity,
                [key]: (<K[]>loadedEntity[key])?.filter((c) => c.id !== id),
              },
            });
            chainDomainStore.deleted(id);
            toast.successFeedback('Delete completed');
          }
        },
      } as WithChildMethods<K>;
    })
  );
}
