import { isEqual } from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';

import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import {
  Component,
  computed,
  effect,
  inject,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CONFIRM_SERVICE_TOKEN,
  CustomGridFilterParams,
  DataGridAction,
  EntityListField,
  FilterConfig,
  getExcelFields,
  getGridColumns,
  mapFromColumnState,
  mapFromFilterModel,
  mapToFilterModel,
  ROUTER_FACADE_TOKEN,
  TOAST_SERVICE_TOKEN,
} from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import { FieldConfig, FilterValue } from '@do/common-interfaces';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from './base.component';
import { EntityDataSignalStore } from './entity-domain-store';
import { EntityFeatureSignalStore } from './entity-feature-store';

@Component({
  template: '',
})
export abstract class BaseEntityListContainer<T extends BaseDto, K>
  extends BaseComponent
  implements OnInit
{
  //  injections
  protected routerFacade = inject(ROUTER_FACADE_TOKEN);
  protected permissionsService = inject(NgxPermissionsService);
  protected modalService = inject(NgbModal);
  protected confirmService = inject(CONFIRM_SERVICE_TOKEN);
  protected toastService = inject(TOAST_SERVICE_TOKEN);
  protected translateService = inject(TranslateService);

  abstract domainStore: EntityDataSignalStore<T>;
  abstract featureStore: EntityFeatureSignalStore<T, K>;

  abstract entity: string;

  abstract fields: Signal<EntityListField[]>;

  @ViewChild('advancedFilters')
  private content?: any;

  private modalRef?: NgbModalRef;

  join?: string[];
  exportJoin?: string[];

  customActions: Signal<DataGridAction[]> = computed(() => {
    const isFindMode = this.routerFacade.isFindMode();
    const actions: DataGridAction[] = [];
    if (isFindMode) {
      actions.push({
        description: 'select',
        icon: 'check_circle',
        class: 'text-primary',
        onAction: (entity: any) => {
          this.select(entity);
        },
        visible: (entity: any) => {
          return entity.id != 0 && !entity.locked;
        },
      });
    }

    return actions;
  });

  presetFilters = computed(() => {
    const queryParams = this.routerFacade.queryParams();

    const filters = queryParams['filters'];
    if (!filters) return [];
    const decoded = atob(queryParams['filters']);
    const queryParamsFilters = JSON.parse(decoded) as FilterValue[];
    return queryParamsFilters;
  });

  filterForm = new FormGroup({ filters: new FormControl<FilterValue[]>([]) });

  // /**
  //  * Observable in ascolto sui parametri in Querystring che preleva
  //  * unicamente il parametro 'isEntitySelectionLimited'.
  //  */
  // isEntitySelectionLimited$ = this.facade.queryParams$.pipe(
  //   map((queryParams) => {
  //     if (!queryParams['isEntitySelectionLimited']) return false;
  //     const encodedIsEntitySelectionLimited =
  //       queryParams['isEntitySelectionLimited'];
  //     const decodedIsEntitySelectionLimited = atob(
  //       encodedIsEntitySelectionLimited
  //     );
  //     return decodedIsEntitySelectionLimited === 'true';
  //   })
  // );

  // abstract filters: FilterConfig[];
  // abstract colDefs: ColDef[];

  // private _filters?: ;
  filters: Signal<FilterConfig[]> = computed(() => {
    const fields = this.fields();
    const presetFilters = this.presetFilters();

    return fields
      .map((f) => ({
        ...f,
        readOnly: presetFilters.find((pf) => pf.field === f.field) != null,
      }))
      .filter(
        (f) =>
          !(
            f.hideFilter &&
            presetFilters.find((pf) => pf.field === f.field) == null
          )
      );
  });

  exportFields: Signal<FieldConfig[]> = computed(() => {
    const fields = this.fields();
    const columnState = this.featureStore.gridState.columnState();

    return getExcelFields(fields, columnState || []);
  });

  exportFilters: Signal<FilterValue[]> = computed(() => {
    const filterModel = this.featureStore.gridState.filterModel();
    const presetFilters = this.presetFilters();

    const filters = mapFromFilterModel(filterModel);
    presetFilters.forEach((sf) => {
      const index = filters.findIndex(
        (f) =>
          f.field === sf.field &&
          f.type === sf.type &&
          f.operator === sf.operator &&
          f.value === sf.value
      );
      if (index >= 0) {
        filters[index] = sf;
      } else {
        filters.push(sf);
      }
    });
    return filters;
  });

  exportSort: Signal<{ [field: string]: 'asc' | 'desc' }> = computed(() => {
    const columnState = this.featureStore.gridState.columnState();
    return mapFromColumnState(columnState);
  });

  columns: Signal<ColDef[]> = computed(() => {
    const fields = this.fields();
    const presetFilters = this.presetFilters();

    return getGridColumns(
      fields.filter((f) => {
        return (
          !f.hideColumn ||
          presetFilters.find((pf) => pf.field === f.field) != null
        );
      }),
      [],
      []
    ).map((colDef) => {
      const f = fields.find((f) => f.field == colDef.field);
      return {
        ...colDef,
        initialHide:
          f?.initialHideColumn &&
          presetFilters.find((pf) => pf.field === f?.field) == null,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          values: f?.elementValues,
          filterType: f?.filterType,
          field: f?.field,
          entityName: f?.entityName,
          hidden: f?.hideFilter,
          readOnly: presetFilters.find((pf) => pf.field === f?.field) != null,
        } as CustomGridFilterParams,
      };
    });

    // fields
    //   .filter((f) => {
    //     return (
    //       !f.hideColumn ||
    //       presetFilters.find((pf) => pf.field === f.field) != null
    //     );
    //   })
    //   .map((f) => {
    //     return {
    //       colId: f.field,
    //       field: f.field,
    //       initialHide:
    //         f.initialHideColumn &&
    //         presetFilters.find((pf) => pf.field === f.field) == null,
    //       headerName: f.elementLabel,
    //       headerClass: `ag-${
    //         contentAlignmentMap[
    //           f.contentAlignment || ContentAlignmentEnum.Left
    //         ]
    //       }-aligned-header`,
    //       type: getGridColumnType(f.filterType, f.fieldType),
    //       filterParams: { suppressAndOrCondition: true },
    //       cellStyle: {
    //         'text-align':
    //           contentAlignmentMap[
    //             f.contentAlignment || ContentAlignmentEnum.Left
    //           ],
    //       },
    //       cellRendererParams: {
    //         elementValues: f.elementValues,
    //         precision: f.precision,
    //       },
    //       floatingFilterComponentParams: {
    //         suppressFilterButton: true,
    //         values: f.elementValues,
    //         filterType: f.filterType,
    //         field: f.field,
    //         entityName: f.entityName,
    //         readOnly:
    //           presetFilters.find((pf) => pf.field === f.field) != null,
    //       } as CustomGridFilterParams,
    //       sortable: f.sortable,
    //       floatingFilter: f.sortable === false ? false : undefined,
    //       filter: f.sortable === false ? false : undefined,
    //       sort: f.sort,
    //       maxWidth: f.maxWidth,
    //       minWidth:
    //         f.minWidth ??
    //         (f.filterType === FilterType.date ||
    //         f.filterType === FilterType.datetime
    //           ? 240
    //           : 200),
    //       valueGetter: f.valueGetter ? f.valueGetter : undefined,
    //     } as ColDef;
    // })

    // return this.entityFilters?.filter((ef) => ef.isFilter);
  });
  // columnDefs: ColDef[] = [];

  protected gridApi?: GridApi;

  commonGridOptions: GridOptions = {
    pagination: false,
    // sideBar: {
    //   toolPanels: [
    //     {
    //       id: 'columns',
    //       labelDefault: 'Colonne',
    //       labelKey: 'columns',
    //       iconKey: 'columns',
    //       toolPanel: 'agColumnsToolPanel',
    //       toolPanelParams: {
    //         suppressPivotMode: true,
    //         suppressRowGroups: true,
    //         suppressValues: true,
    //         suppressColumnFilter: true,
    //       },
    //     },
    //   ],
    // },
    suppressPaginationPanel: true,
    onGridReady: (e: GridReadyEvent) => this.onGridReady(e),
    onFilterChanged: (e: FilterChangedEvent) => this.onFilterChanged(e),
    onSortChanged: (e: SortChangedEvent) => this.onColumnStateChanged(e),
    onColumnVisible: (e: ColumnVisibleEvent) => this.onColumnStateChanged(e),
    onColumnMoved: (e: ColumnMovedEvent) => this.onColumnStateChanged(e),
    onColumnResized: (e: ColumnResizedEvent) => this.onColumnStateChanged(e),
    defaultColDef: {
      floatingFilter: true,
      resizable: true,
      filter: true,
      suppressMenu: true,
      floatingFilterComponent: 'customGridFilter',
    },
    // columnTypes: {
    //   numberColumn: {
    //     filter: 'agNumberColumnFilter',
    //   },
    //   // enumColumn: {
    //   //   cellRenderer: 'gridComponentEnumRenderer',
    //   // },
    //   // dateColumn: {
    //   //   filter: 'agDateColumnFilter',
    //   // },
    // },
  };

  // filterFormState$ = this.featureStore.entityFilterForm();
  // pageSize$ = this.facade.getPageSize$;
  // currentPage$ = this.facade.getCurrentPage$;
  // totalRows$ = this.facade.getTotalRows$;
  // filterModel$ = this.facade.getFilterModel$;

  // canDownloadExcel$ = this.sharedFacade.canRead$;
  // canDelete$ = this.facade.canDelete$;
  // canEdit$ = this.facade.canEdit$;
  // canAdd$ = this.facade.canAdd$;
  // canView$ = this.sharedFacade.canRead$;

  // findMode$ = this.routerFacade.isFindMode$;
  // modalListMode$ = this.routerFacade.isModalListMode$;

  gridOptions!: GridOptions;

  activeFiltersCount = computed(
    () => Object.entries(this.featureStore.gridState.filterModel() || {}).length
  );

  constructor() {
    super();

    effect(() => {
      const pageSize = this.featureStore.gridState.pageSize();
      this.pageSizeChanged(pageSize);
    });

    effect(
      () => {
        let filterModel = this.featureStore.gridState.filterModel();
        const presetFilters = this.presetFilters();
        const filterForm = this.featureStore.entityFilterForm();

        //console.log('filterModel', filterModel);
        const gridFilters = mapToFilterModel(presetFilters);
        filterModel = { ...filterModel, ...gridFilters };
        if (!isEqual(filterModel, this.gridApi?.getFilterModel())) {
          this.gridApi?.setFilterModel(filterModel);
        }

        const newFilters = mapFromFilterModel(filterModel);
        const filterFormValue = filterForm.filters;
        if (!isEqual(newFilters, filterFormValue)) {
          this.featureStore.setFilterFormValue(newFilters);
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const sortModel = this.featureStore.gridState.columnState();

      if (sortModel && !isEqual(sortModel, this.gridApi?.getColumnState())) {
        this.gridApi?.applyColumnState({
          state: sortModel,
          applyOrder: true,
        });
      }
    });

    effect(() => {
      const currentPage = this.featureStore.gridState.currentPage();
      const pageLoaded = this.featureStore.gridState.pageLoaded();

      if (pageLoaded) {
        this.gridApi?.paginationGoToPage(currentPage - 1);
      }
    });

    effect(async () => {
      const show = this.featureStore.showAdvancedFilters();
      if (show) {
        this.modalRef = this.modalService.open(this.content, {
          size: 'xl',
        });

        try {
          await this.modalRef.result;
        } catch (err) {
          this.showAdvancedFilters(false);
        }
      } else {
        this.modalRef?.close();
      }
    });
  }

  ngOnInit(): void {
    // stessi permessi di lettura
    this.gridOptions = Object.assign(this.commonGridOptions, {});
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  onPageChange(newPage: number) {
    this.featureStore.setCurrentPage(newPage);
  }

  onPageSizeChanged(size: number) {
    this.featureStore.setPageSize(size);
  }

  pageSizeChanged(pageSize: number) {
    // (<any>api).gridOptionsWrapper.setProperty('cacheBlockSize', pageSize);
    // api.purgeInfiniteCache();
    this.gridApi?.setGridOption('paginationPageSize', pageSize);
  }

  onColumnStateChanged(
    e:
      | SortChangedEvent
      | ColumnVisibleEvent
      | ColumnMovedEvent
      | ColumnResizedEvent
  ) {
    this.featureStore.setColumnState(e.api.getColumnState());
  }

  // Ricerca da griglia
  onFilterChanged(e: FilterChangedEvent) {
    this.featureStore.setFilterModel(e.api.getFilterModel());
  }

  // Ricerca da form esterno
  search(filters: FilterValue[]) {
    const externalFilters = mapToFilterModel(filters);
    this.featureStore.setFilterModel(externalFilters);

    this.showAdvancedFilters(false);
  }

  showAdvancedFilters(show: boolean) {
    this.featureStore.toggleAdvancedFilters(show);
  }

  async excel() {
    const columns = this.exportFields();
    const filters = this.exportFilters();
    const sort = this.exportSort();

    alert('todo download excel');
    // console.log(columns, filters, sort);

    // this.featureStore.downloadExcel(
    //   fileName,
    //   columns,
    //   this.exportJoin ? this.exportJoin : this.join,
    //   filters,
    //   sort
    // );
  }

  // protected mapToFilterModel(
  //   filterModel: AgGridFilterModel,
  //   filters: FilterValue[]
  // ) {
  //   return filters.reduce((res, f) => {
  //     res[f.field] = {
  //       type: FilterOperator[f.operator],
  //       filterType: FilterType[f.type],
  //       filter: f.value.length ? f.value[0] : undefined,
  //     };
  //     return res;
  //   }, filterModel);
  // }

  // protected mapFromFilterModel(filterModel: AgGridFilterModel) {
  //   const filters: FilterValue[] = [];
  //   Object.keys(filterModel).forEach((key) => {
  //     const gridFilter = filterModel[key];
  //     filters.push({
  //       field: key,
  //       operator: (<any>FilterOperator)[gridFilter.type],
  //       type: (<any>FilterType)[gridFilter.filterType],
  //       value: [gridFilter.filter],
  //     });
  //   });
  //   return filters;
  // }

  add() {
    // this.facade.formInitialize(undefined);
    this.featureStore.addEntity();
    // this.facade.addEntity();
  }

  edit(entity: T) {
    this.routerFacade.navigateRelative([entity.id]);
    // this.routerFacade.router.navigateByUrl('./' + id);
  }

  view(entity: T) {
    this.routerFacade.navigateRelative([entity.id]);
    //this.routerFacade.viewEntityDetail(this.entity, entity.id);
  }

  async delete(entity: T) {
    const result = await this.confirmService.deleteConfirm();

    if (result) {
      const deleteSucceeded = await this.domainStore.delete(entity.id);
      if (deleteSucceeded) {
        this.toastService.successFeedback(
          this.translateService.instant('Delete completed')
        );

        this.refreshList();
      }
    }
  }

  select(item: T) {
    this.featureStore.selectItem(item);
  }

  refreshList() {
    // refresh lista paginata
    if (this.gridApi) {
      this.gridApi.refreshServerSide(); // { route: route, purge: purge }
    }
  }
}
