import moment from 'moment';
import { firstValueFrom, map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientInterface } from '@do/app-common';
import {
  FieldConfig,
  FilterOperator,
  FilterType,
  FilterValue,
  PagedResultDto,
} from '@do/common-interfaces';

@Injectable()
export abstract class BaseApiClient<T> implements ApiClientInterface<T> {
  abstract baseUrl: string;

  constructor(protected http: HttpClient) {}

  getAll() {
    return this.http.get<T[]>(this.baseUrl);
  }

  getAllPromise() {
    return firstValueFrom(this.getAll());
  }

  getPaged(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) {
    const mappedFilters = this.buildPagedFilters(filters);

    return this.http.post<PagedResultDto<T>>(`${this.baseUrl}/paged`, {
      skip,
      take,
      orderBy,
      filters: mappedFilters,
      join,
    });
  }

  getPagedPromise(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) {
    return firstValueFrom(this.getPaged(take, skip, orderBy, filters, join));
  }

  downloadExcel(
    fileName?: string,
    columns?: FieldConfig[],
    filters?: FilterValue[],
    orderBy?: { [field: string]: 'asc' | 'desc' },
    join?: string[]
  ): Observable<ArrayBuffer> {
    return this.http
      .post(
        this.baseUrl + '/download-excel',
        { fileName, columns, filters: filters || [], orderBy, join },
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  downloadPdf(
    fileName?: string,
    columns?: FieldConfig[],
    filters?: FilterValue[],
    orderBy?: { [field: string]: 'asc' | 'desc' },
    join?: string[]
  ): Observable<ArrayBuffer> {
    return this.http
      .post(
        this.baseUrl + '/download-pdf',
        { fileName, columns, filters, orderBy, join },
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  protected buildPagedFilters(filters: FilterValue[] | undefined) {
    return filters?.map((filter) => {
      if (filter.type !== FilterType.datetime) return filter;
      else {
        switch (filter.operator) {
          case FilterOperator.equals:
            return {
              ...filter,
              operator: FilterOperator.inRange,
              value: [
                moment(filter.value[0]).startOf('d').toISOString(),
                moment(filter.value[0]).endOf('d').toISOString(),
              ],
            };
          case FilterOperator.greaterThan:
            return {
              ...filter,
              value: [moment(filter.value[0]).endOf('d').toISOString()],
            };
          case FilterOperator.greaterThanOrEqual:
            return {
              ...filter,
              value: [moment(filter.value[0]).startOf('d').toISOString()],
            };
          case FilterOperator.lessThan:
            return {
              ...filter,
              value: [moment(filter.value[0]).startOf('d').toISOString()],
            };
          case FilterOperator.lessThanOrEqual:
            return {
              ...filter,
              value: [moment(filter.value[0]).endOf('d').toISOString()],
            };

          case FilterOperator.inRange:
            return {
              ...filter,
              value: [
                moment(filter.value[0]).startOf('d').toISOString(),
                moment(filter.value[1]).endOf('d').toISOString(),
              ],
            };

          default:
            return {
              ...filter,
            };
        }
      }
    });
  }
}
