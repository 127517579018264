import { computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem, ROUTER_FACADE_TOKEN } from '@do/app-common';
import { BaseDto } from '@do/common-dto';

import { BaseComponent } from './base.component';
import { EntityFeatureSignalStore } from './entity-feature-store';

export abstract class BaseRootContainer<
  T extends BaseDto,
  K
> extends BaseComponent {
  protected activatedRoute = inject(ActivatedRoute);
  protected router = inject(ROUTER_FACADE_TOKEN);

  abstract featureStore: EntityFeatureSignalStore<T, K>;
  // abstract entityListText: string;
  // abstract entityNewText: string;
  // abstract ENTITY: string;
  // abstract ENTITY_PROP: string;

  showBreadcrumb = computed(() => {
    const entityId = this.featureStore.selectedId();

    const isEditMode = this.router.isEditMode();
    const isFindMode = this.router.isFindMode();
    const isViewOnlyMode = this.router.isViewOnlyMode();
    const isModalListMode = this.router.isModalListMode();
    // const isIframe = this.router.isIframe();

    // if (isIframe) return true;

    if (entityId == null) {
      return !isEditMode && !isFindMode && !isViewOnlyMode && !isModalListMode;
    } else {
      return !isEditMode && !isFindMode && !isViewOnlyMode;
    }
  });

  async navigate(idx: number, items: BreadcrumbItem[]) {
    const url = items
      .filter((item, index) => index <= idx)
      .map((i) => i.link)
      .join('/');

    const item = items[idx];

    if (item && item.beforeNavigate) item.beforeNavigate();

    await this.router.navigateRelative([url], undefined, this.activatedRoute);
  }
}
