import { filter, first, map } from 'rxjs';

import { inject, Type } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { BaseDto } from '@do/common-dto';

import { EntityDataSignalStore } from './entity-domain-store';

export function baseLoadAllGuard<
  E extends BaseDto,
  S extends EntityDataSignalStore<E>
>(storeType: Type<S>): CanActivateFn {
  return () => {
    const domainStore = inject(storeType);

    return toObservable(domainStore.loadedAll).pipe(
      map((loaded) => {
        if (!loaded) {
          domainStore.loadAll();
        }
        return loaded;
      }),
      filter((loaded) => loaded),
      first()
    );
  };
}
