import { firstValueFrom } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CRUDApiClientInterface } from '@do/app-common';
import { DeepPartial } from '@do/common-utils';

import { BaseApiClient } from './base.api-client';

@Injectable()
export abstract class BaseCRUDApiClient<T>
  extends BaseApiClient<T>
  implements CRUDApiClientInterface<T, string>
{
  getById(id: string) {
    return this.http.get<T>(`${this.baseUrl}/${id}`);
  }

  getByIdPromise(id: string) {
    return firstValueFrom(this.getById(id));
  }

  add(u: DeepPartial<T>) {
    return this.http.post<T>(`${this.baseUrl}`, u);
  }

  addPromise(u: DeepPartial<T>) {
    return firstValueFrom(this.add(u));
  }

  update(id: string, u: DeepPartial<T>) {
    return this.http.put<T>(`${this.baseUrl}/${id}`, u);
  }

  updatePromise(id: string, u: DeepPartial<T>) {
    return firstValueFrom(this.update(id, u));
  }

  delete(id: string) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }),
    });
  }

  deletePromise(id: string) {
    return firstValueFrom(this.delete(id));
  }
}
